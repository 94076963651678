<template>
  <div class="login">
    <div class="login-main">
      <div class="login-main-right" :class="{'forget-code': isFindPage}">
        <div style="margin-top: 50px;" class="main-text" :class="{'pwd-text': isFindPage}">
          <img style="width: 144px; height: 75px;margin-right: 13px;vertical-align: middle;" 
          src="../../assets/images/logo_icon.png" alt="">
        </div>
        <!--登录-->
        <el-form v-show="!isFindPage"
                label-position="left"
                :show-message="false"
                status-icon
                :model="login" :rules="rules" ref="form" label-width="0px">
          <el-form-item prop="username">
            <el-input @keyup.enter.native="loginFun"
                      v-model="login.username"
                      maxlength="64"
                      placeholder="请输入登录账号">
              <i slot="prefix" class="el-input__icon iconfont icongerenzhongxin"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password"
                      maxlength="18"
                      v-model="login.password" placeholder="请输入密码">
              <i slot="prefix" class="el-input__icon iconfont iconmima"></i>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="bottom-main" v-show="!isFindPage">
          <span @click="isFindPage=true" class="back-pwd curp">忘记密码</span>
          <div @click="loginFun" class="btn-submit curp" v-loading="loginLoading">登录</div>
        </div>

        <!--忘记密码-->
        <div v-if="isFindPage">
          <el-form label-position="left"
                   status-icon
                   :model="pwdForm"
                   :show-message="false"
                   :rules="pwdRules" ref="pwdForm" label-width="0px">
            <el-form-item prop="username">
              <el-input @keyup.enter.native="pwdFun"
                        v-model="pwdForm.username"
                        maxlength="11"
                        placeholder="请输入手机号">
                <i slot="prefix" class="el-input__icon iconfont iconshoujihao"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="phoneCode">
              <div style="position: relative;">
                <el-input @keyup.enter.native="pwdFun"
                        maxlength="6"
                        v-model="pwdForm.phoneCode"
                        placeholder="请输入验证码"
                        ></el-input>
                <div style="position: absolute; display: inline-block; right: 0px; top: 0px;" class="code-btn curp" @click="getCode" :class="{'code-active': typeof countdown === 'number'}">
                  {{countdown}}
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password"
                        maxlength="18"
                        v-model="pwdForm.password"
                        placeholder="请输入新密码">
                <i slot="prefix" class="el-input__icon iconfont iconmima"></i>
              </el-input>
            </el-form-item>
            <!-- 确认密码-->
            <el-form-item prop="newPassword">
              <el-input type="password"
                        maxlength="18"
                        v-model="pwdForm.newPassword"
                        placeholder="请再次输入新密码">
                <i slot="prefix" class="el-input__icon iconfont iconmima"></i>
              </el-input>
            </el-form-item>
          </el-form>
          <div @click="pwdFun" class="btn-submit curp" style="margin-top: 42px" v-loading="loginLoading">确认</div>
          <span class="back-log mt15px curp" @click="isFindPage=false">去登录</span>
        </div>
      </div>
      <div class="beian">
        <p>Copyright@2018湖南艾依灵科技有限公司 <a href="http://www.beian.miit.gov.cn/" target="_blank" class="lianjie">湘ICP备 18013163号</a> {{version}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const validateUser = (rule, value, callback) => {
        if (!value){
          callback(new Error('请输入11位手机号'))
          return
        }
        if(value.length<11||!(/^[1][0-9][0-9]{9}$/.test(value))) {
          callback(new Error('请输入正确的手机号码'))
        }else {
          callback()
        }
      };
      const validateNewPassword = (rule, value, callback) => {
        if (!value){
          callback(new Error())
          return
        }
        if(value !== this.pwdForm.password) {
          callback(new Error())
          this.$message.warning('密码输入不一致')
        }else {
          callback()
        }
      };
      return {
        debug: 0,
        version:`v${process.env.VUE_APP_VERSION}`,
        // 是否记住密码变量
        checked: false,
        loginLoading: false,
        login: {
          // username: '17701963306',
          username: null,
          password: null,
          // password: 'Zz1234567',
          verifyCode: null,
        },
        pwdForm: {
          username: null,
          password: null,
          newPassword: null,
          phoneCode: null,
        },
        loginData: {},
        isFindPage: false,
        rules: {
          username: [
            // min: 4, max: 64,
            {min: 1, max: 64,required: true, trigger: 'blur'}
          ],
          password: [
            {min: 6, max: 18, required: true, trigger: 'blur'}
          ],
          verifyCode: [
            {min: 4, max: 4, required: true, trigger: 'blur'}
          ],
        },
        pwdRules: {
          username: [
            {validator: validateUser, required: true, trigger: 'blur'}
          ],
          password: [
            {min: 6, max: 18, required: true, trigger: 'blur'}
          ],
          newPassword: [
            {validator: validateNewPassword, required: true, trigger: 'blur'}
          ],
          phoneCode: [
            {min: 6, max: 6, required: true, trigger: 'blur'}
          ],
        },
        countdown: '获取验证码',
        timer: null
      }
    },
    methods: {
      // 生成随机码传给后台
      uuid(){
        const s = [];
        const hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = "-";
        const uuid = s.join("");
        return uuid;
      },
      // 点击登录
      async loginFun(){
        if (!this.login.username) {
          this.$message.warning('请输入账号或手机号码')
          return
        }
        if (!this.login.password) {
          this.$message.warning('请输入密码')
          return
        }

        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.btnLoading = true

          try {
            const mac = this.uuid()
            const loginData =  await this.$api.Login({
              userIdentifier: this.login.username,
              // userType: 1,
              loginType: 1,
              loginCode: "",
              password: this.login.password,
              mac: mac
            })
            // 缓存登录信息
            sessionStorage.setItem('loginData', JSON.stringify(loginData))
            sessionStorage.setItem('userIdentifier', this.login.username)
            sessionStorage.setItem('mac', mac)
            // 缓存菜单及用户信息
            let menus = await this.$api.Menu({})
            // console.log(menus)
            if(menus.length===0){
              this.$message.warning('请联系管理员分配菜单权限')
              this.loginLoading = false
              this.login.password = null
              return
            }
            sessionStorage.setItem('menus', JSON.stringify(menus))
            const userInfo = await this.$api.Self({})
            sessionStorage.setItem('userInfos', JSON.stringify(userInfo))
            sessionStorage.setItem('customInfo', JSON.stringify(userInfo.customInfo))
            sessionStorage.setItem('additional', JSON.stringify(userInfo.additional))

            // sessionStorage.setItem('scope', 'SANITATION')// SANITATION 环卫系统 
            sessionStorage.setItem('scope', 'TMS')// TMS 物流系统

            

            document.title = '' + JSON.parse(userInfo.customInfo).pageTitle;
            document.querySelector("link[rel*='icon']").href =  this._(JSON.parse(userInfo.customInfo).icon); 

            this.$notify({
              title: '成功',
              message: `登录成功！`,
              type: 'success'
            })

            // console.log(menus[0].path)
            // if(menus[0].path !== ''){
            //   this.$router.replace({path: menus[0].path})
            // }

            if(menus[0].path === ''){
              this.$router.replace({path: menus[0].children[0].path})
            }else{
              this.$router.replace({path: menus[0].path})
            }
            this.login.password = null
          // eslint-disable-next-line no-empty
          } catch (e) {
            console.error(e)
          }
          this.btnLoading = false
        })
      },
      _(url) {
        if(!url)return "";
        /*
        let i = url.lastIndexOf("/");
        if (i != -1) {
          url = document.location.origin + url.substring(i);
        }
        console.log(url);*/
        return url;
      },
      // 获取手机验证码
      async getCode(){
        if (typeof this.countdown === 'number') {
          return
        }
        if(!this.pwdForm.username) {
          this.$message.warning('请输入11位手机号')
          return
        }
        if (!(/^[1][0-9][0-9]{9}$/.test(this.pwdForm.username))) {
          this.$message.warning('请输入正确手机号码')
          return
        }

        try {
          // 验证用户是否已注册为平台用户
          let _isUser = await this.$api.UserIdVerify({
            userIdentifier: this.pwdForm.username
          })
          if (typeof _isUser ==='object') {
            this.$message.warning('该账号不存在，请重新输入')
            return
          }
          await this.$api.SendVerifyCode({
            codeType: 2,
            userIdentifier: this.pwdForm.username,
            // userType: 1
          })
          this.$notify.success({
            title: '成功',
            message: '验证码发送成功'
          })
          this.countdown = 120
          this.timer = setInterval(() => {
            this.countdown--
            if (this.countdown === 0) {
              clearInterval(this.timer)
              this.countdown = '重新获取'
            }
          }, 1000)
        }catch (e) {
          // console.log(1)
        }
      },
      // 忘记密码
      pwdFun(){
        if (!this.pwdForm.username) {
          this.$message.warning('请输入11位手机号')
          return
        }
        // if (!(/^(?![0-9a-z!@#$^&*]+$)(?![a-zA-Z!@#$^&*]+$)(?![0-9A-Z!@#$^&*]+$)[0-9A-Za-z!@#$^&*]{8,16}$/.test(this.pwdForm.password))) {
        // if (!(/^(?![0-9a-z!@#$^&*]+$)(?![a-zA-Z!@#$^&*]+$)(?![0-9A-Z!@#$^&*]+$)[0-9A-Za-z!@#$^&*]{8,16}$/.test(this.pwdForm.password))) {
        //   this.$message.warning('密码格式有误！长度为8-16位，必须包括大小写字母数字，特殊字符仅限「 !@#$^&* 」')
        //   return
        // }
        if (!this.pwdForm.phoneCode) {
          this.$message.warning('请输入验证码')
          return
        }
        if (this.pwdForm.phoneCode.length<6) {
          this.$message.warning('请输入6位验证码')
          return
        }
        if (!this.pwdForm.password) {
          this.$message.warning('请输入新密码')
          return
        }
        if (!this.pwdForm.newPassword) {
          this.$message.warning('请再次输入新密码')
          return
        }
        this.$refs.pwdForm.validate( async (valid)=>{
          if (!valid) return
          if (typeof this.countdown !== 'number') {
            this.$message.warning('请先获取验证码')
            return
          }
          this.loginLoading = true
          try {
            await this.$api.PasswordOperate({
              userIdentifier: this.pwdForm.username,
              // userType: 1,
              verifyMessage: this.pwdForm.phoneCode,
              pwd: this.pwdForm.password,
              // mac: sessionStorage.getItem("mac"),
            })
            this.$notify.success({
              title: '成功',
              message: '密码修改成功'
            })
            this.isFindPage = false
            this.pwdForm.username= null
            this.pwdForm.password= null
            this.pwdForm.newpassword= null
            this.pwdForm.phoneCode= null
          }catch (e) {}
          this.loginLoading = false
        })
      },
      // 记住密码
      remember(){
        this.$Cookies.set('checked', this.checked, 1)
        if (this.checked) {
          this.$Cookies.set('username', this.login.username, 1)
          this.$Cookies.set('password', this.login.password, 1)

          // $Cookie.setCookie('username', this.login.username, 30 * 24)
          // $Cookie.setCookie('password', this.login.password, 20 * 24)
        } else {
          this.$Cookies.remove('username')
          this.$Cookies.remove('password')
        }
      }
    },
    watch: {
      "isFindPage": function () {
        if (!this.isFindPage)  {
          this.pwdForm.username= null
          this.pwdForm.password= null
          this.pwdForm.phoneCode= null
        }
      }
    },
    mounted () {
      sessionStorage.clear()
      this.log("login mounted");
    },
  }
</script>

<style lang='sass' scoped>
.forget-code
  width: 420px
  height: 600px !important
.lianjie
  color: #fff
  &:hover
    color: #0575E6
.login
  width: 100%
  height: 100vh
  position: relative
  background: url("../../assets/images/ailivingtech_bg.png") no-repeat 100% / cover
  &-main
    width: 100%
    height: 100vh
    &-right
      position: absolute
      top: 50%
      left: calc(100% - 420px)
      transform: translate(-50%,-50%)
      width: 420px
      height: 493px
      background-color: #fff
      vertical-align: top
      display: inline-block
      border-radius: 0 4px 4px 0
      box-shadow: 0px 5px 7px 0px rgba(4,8,49,0.4)
      padding: 0px 90px
      .main-text
        text-align: center
        font-size: 20px
        color: rgba(38,38,38,1)
        font-weight: bold
        letter-spacing: 3px
        margin-bottom: 60px
      .pwd-text
        letter-spacing: 2px
      /deep/ .el-form-item .el-input__inner
        border: 0
        background-color: #F2F6FA
        height: 42px
        line-height: 42px
        border-radius: 21px
        padding-left: 46px
      /deep/ .el-input__prefix
        margin-left: 18px
      /deep/ .el-form-item--mini.el-form-item
        margin-bottom: 30px
        &:last-child
          margin-bottom: 13px
      .code-input
        width: 240px
        /deep/ .el-input__inner
          padding-left: 46px
      .img-code
        width: 100px
        height: 42px
        display: inline-block
        vertical-align: bottom
        margin-left: 20px
        border-radius: 21px
        text-align: center
        line-height: 42px
        color: $grayShallow
        position: relative
        &:hover
          .shadow
            opacity: 1
        .shadow
          position: absolute
          top: 0
          width: 100%
          height: 100%
          background-color: rgba(0,0,0,.3)
          opacity: 0
          transition: opacity .35s
          i
            color: #FFFFFF
            font-size: $fontLarge
      .code-btn
        @extend .img-code
        color: $blue
      .code-active
        border-color: $grayShallow2
        color: $gray
      .bottom-main
        @extend %clearfix
        .gary
          margin-left: 22px
          /deep/ .el-checkbox__label
            font-size: $fontSmall
        .back-pwd
          float: right
          margin-right: 19px
          font-size: $fontSmall
          margin-top: 3px
          color: $blue
          text-decoration: underline
      .btn-submit
        margin-top: 30px
        width: 100%
        height: 42px
        line-height: 42px
        border-radius: 24px
        text-align: center
        background: linear-gradient(0deg,rgba(5,117,230,1),rgba(0,150,255,1))
        color: #FFFFFF
        letter-spacing: 2px
        transition: .35s
        background-size: 200% auto
        font-size: $fontNormal
        &:hover
          background-position: right center
      .back-log
        display: block
        text-align: center
        font-size: $fontSmall
        color: $blue
        text-decoration: underline
.beian
  width: 100%
  padding-top: calc(100vh - 35px)
  text-align: center
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: #eeeeee
.bannerImg
  width: 100%
  height: 100%
/deep/ .el-carousel__container
  width: 100%
  height: 100vh
.src-code
  width: 98px
  margin-top: 14px
  text-align: center
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(51,51,51,1)
</style>
